import React from "react"
import { Box, BoxProps, Divider, Grid, Typography } from "@material-ui/core"
import { currencyString } from "src/helpers"
import { PaymentType } from "src/types"
import { Button } from "src/components/Material/Button"
import { useStyles } from "./Details.styles"
import clsx from "clsx"

interface PaymentConfirmationDetailsProps {
    boxProps?: BoxProps
    payment?: PaymentType
}

export function PaymentConfirmationDetails({ boxProps, payment }: PaymentConfirmationDetailsProps) {
    const classes = useStyles()

    const {
        customer_name,
        line_items = [],
        amount_total,
    } = payment || {}

    return (
        <Box className={clsx(classes.root, boxProps?.className)}>
            <Typography className={classes.title}>
                Detalles de tu pago
            </Typography>

            {customer_name && (
                <Box className={classes.customerDetailsContainer}>
                    <Typography className={classes.customerText}>
                        <strong>Nombre del cliente:</strong> {customer_name}
                    </Typography>
                </Box>
            )}

            <Divider className={classes.divider} />

            <Typography className={classes.paymentSummaryText}>
                Resumen de pago
            </Typography>

            <Box className={classes.paymentBreakdownContainer}>
                {line_items.length > 0 && (
                    <Grid container style={{ marginBottom: 60, gap: '16px' }}>
                        {line_items.map((item, index) => {
                            const { description, amount_total } = item
                            return (
                                <Grid key={index} item xs={12}>
                                    <Box className={classes.concept}>
                                        <Typography> {description || '-'} </Typography>
                                        <Typography>
                                            {(amount_total || 0) > 0 && currencyString(amount_total! / 100)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                )}

                <Grid container style={{ marginBottom: 80, gap: '16px' }}>
                    {amount_total && (
                        <Grid item xs={12}>
                            <Box className={clsx(classes.concept, "total")}>
                                <Typography> Total: {currencyString(amount_total / 100) || '-'} </Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <Box className={classes.actionsContainer}>
                <Button
                    fullWidth
                    size="small"
                    color="primary"
                    variant="outlined"
                    to="/"
                    className={classes.actionButton}
                >
                    Volver a inicio
                </Button>
            </Box>
        </Box>
    )
}