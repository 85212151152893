import React, { useEffect, useState } from "react"
import { Box, CircularProgress } from "@material-ui/core"
import { PaymentType } from "src/types"
import { useQueryParams } from "src/hooks"
import { koncierge } from "src/services"
import { PaymentConfirmationAside } from "../Aside"
import { PaymentConfirmationDetails } from "../Details"
import { useStyles } from "./Confirmation.styles"
import cx from "clsx"

export function PaymentConfirmationView() {
    const { session, org }: { session?: string, org?: string } = useQueryParams()
    const classes = useStyles()

    const [payment, setPayment] = useState<PaymentType>()
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const hasPaymentData = Boolean(payment?.customer_name || payment?.amount_total)

    useEffect(() => {
        if (!session)
            return

        (async () => fetchPayment(session, org))()
    }, [session, org])

    async function fetchPayment(session: string, org?: string) {
        try {
            const payment = await koncierge.stripe.getPayment(session, org)

            setIsLoading(false)
            setPayment(payment)
        } catch (e) {
            console.error('Error getting payment', e)
            setIsLoading(false)
        }
    }

    return (
        <section className={classes.root}>
            <Box
                className={cx(classes.container, {
                    [classes.defaultContainer]: !hasPaymentData
                })}
            >
                {session && isLoading ? (
                    <Box className={classes.loadingWrapper}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box
                        className={cx(classes.confirmationWrapper,
                            { [classes.displayBlock]: !hasPaymentData }
                        )}
                    >
                        <Box className={classes.asideContainer}>
                            <PaymentConfirmationAside
                                boxProps={{
                                    className: cx(classes.aside, {
                                        [classes.asideWithoutDetails]: !hasPaymentData
                                    })
                                }}
                            />
                        </Box>

                        {hasPaymentData && (
                            <Box className={classes.paymentDetailsContainer}>
                                <PaymentConfirmationDetails
                                    boxProps={{ className: classes.paymentDetails }}
                                    payment={payment}
                                />
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </section>
    )
}