import {
    DEFAULT_LEFT_PADDING,
    DEFAULT_RIGHT_PADDING,
    MOBILE_LEFT_PADDING,
    MOBILE_RIGHT_PADDING,
    WINDOW_MAX_WIDTH
} from "src/layout/Layout.styles"

import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 21,
        paddingBottom: 21 * 4,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 24,
            paddingBottom: 24 * 4,
        }
    },
    container: {
        margin: '0 auto',
        minHeight: 499,
        maxWidth: WINDOW_MAX_WIDTH,
        paddingLeft: DEFAULT_LEFT_PADDING,
        paddingRight: 0,
        overflow: 'hidden',
        position: 'relative',
        [theme.breakpoints.down('xl')]: {
            paddingLeft: 48,
            paddingRight: 48,
        },
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            paddingLeft: MOBILE_LEFT_PADDING,
            paddingRight: MOBILE_RIGHT_PADDING,
        }
    },
    defaultContainer: {
        paddingRight: DEFAULT_RIGHT_PADDING,
        [theme.breakpoints.down('xl')]: {
            paddingLeft: DEFAULT_LEFT_PADDING,
            paddingRight: DEFAULT_RIGHT_PADDING,
        },
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            paddingLeft: MOBILE_LEFT_PADDING,
            paddingRight: MOBILE_RIGHT_PADDING,
        }
    },
    confirmationWrapper: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            justifyContent: 'initial',
            gap: 24,
        }
    },
    displayBlock: {
        display: 'block'
    },
    asideContainer: {
    },
    aside: {
        height: 724,
        maxWidth: '100%',
        width: 671,

        [theme.breakpoints.down('md')]: {
            height: 'auto',
            width: '100%',
        }
    },
    asideWithoutDetails: {
        margin: '0 auto',
        width: '100%',

        [theme.breakpoints.down('md')]: {
            height: '74vh',
            maxHeight: 800
        }
    },
    paymentDetailsContainer: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        padding: 16,
        maxWidth: 600,
        [theme.breakpoints.down('md')]: {
            padding: 0,
            maxWidth: '100%',
        }
    },
    paymentDetails: {
        maxWidth: '100%',
        width: 455,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }
    },
    loadingWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 724,
        [theme.breakpoints.down('md')]: {
            height: '74vh',
        }
    }
}))