import React from "react"
import { Box, BoxProps, Typography } from "@material-ui/core"
import { Img } from "src/components/Image"
import { useStyles } from "./Aside.styles"
import clsx from "clsx"

// @ts-ignore
import CheckImg from "./assets/check.png"

// @ts-ignore
import SplashImg from "./assets/splash.png"

interface PaymentConfirmationAsideProps {
    boxProps?: BoxProps
}

export function PaymentConfirmationAside({ boxProps }: PaymentConfirmationAsideProps) {
    const classes = useStyles()

    return (
        <Box className={clsx(classes.root, boxProps?.className)}>
            <Box className={classes.container}>
                <Box className={classes.iconWrapper}>
                    <Img
                        loadingType="eager"
                        className={classes.icon}
                        src={CheckImg}
                    />
                </Box>

                <Typography className={classes.title}>
                    Gracias por tu pago
                </Typography>

                <Typography className={classes.subtitle}>
                    Tu pago se completó con éxito
                </Typography>
            </Box>

            <Box className={classes.splashContainer}>
                <Img
                    className={classes.splash}
                    loadingType="eager"
                    src={SplashImg}
                />
            </Box>
        </Box>
    )
}