import React, { FC } from "react"
import { PageProps } from "gatsby"
import { Layout } from "src/layout"
import { PaymentConfirmationView } from "src/components/pages/PaymentConfirmation"
import { PaymentType } from "src/types/payment"

type ServerDataProps = {
    payment?: PaymentType
    org?: string
}

type PaymentConfirmationPageProps = PageProps<{}, {}, {}, ServerDataProps>

const PaymentConfirmationPage: FC<PaymentConfirmationPageProps> = ({ location }) => {
    return (
        <Layout location={location}>
            <PaymentConfirmationView />
        </Layout>
    )
}

export default PaymentConfirmationPage