import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.text.primary,
        justifyContent: 'center',
        overflow: 'hidden',
    },
    container: {
        alignItems: 'center',
        color: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        maxWidth: '100%',
        width: 486,
        marginBottom: 40,

        [theme.breakpoints.down('sm')]: {
            padding: 16,
            gap: 8,
            width: '100%',
            marginBottom: 16,
        }
    },
    splashContainer: {
    },
    splash: {
        display: 'block',
        borderRadius: 10,
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            minWidth: 360,
            width: '100%'
        }
    },
    title: {
        color: 'inherit',
        fontSize: 34,
        fontWeight: 700,
        lineHeight: '40px',
        letterSpacing: 'normal',

        [theme.breakpoints.down('sm')]: {
            fontSize: 28,
            lineHeight: 'normal',
            flex: '1 1 auto'
        }
    },
    subtitle: {
        color: 'inherit',
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '0.15px',

        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: 'normal',
            flexBasis: '100%'
        }
    },
    iconWrapper: {
        [theme.breakpoints.down('sm')]: {
            flex: '0 0 auto'
        }
    },
    icon: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            width: (64 / 2) + 6
        }
    }
}))