import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
    root: {
    },
    title: {
        color: 'inherit',
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '30px',
        letterSpacing: '0.18px',
        marginBottom: 32,

        [theme.breakpoints.down('md')]: {
            fontSize: 20,
            lineHeight: 'normal',
            marginBottom: 16,
        }
    },
    customerDetailsContainer: {
        marginBottom: 16,
    },
    customerText: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '26px',
        letterSpacing: '0.15px',

        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            lineHeight: 'normal',
        }
    },
    divider: {
        marginBottom: 16,
    },
    paymentSummaryText: {
        color: theme.palette.primary.main,
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '0.15px',
        marginBottom: 16,
    },
    paymentBreakdownContainer: {
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
    },
    actionButton: {
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '20px',
        letterSpacing: '0.1px',
        height: 44,
    },
    concept: {
        display: 'flex',
        justifyContent: 'space-between',
        '& > p': {
            fontSize: 16,
            lineHeight: "22px",
            letterSpacing: "0.15px"
        },
        '&.total': {
            justifyContent: 'flex-end',
            '& > p': {
                fontSize: 20,
                lineHeight: "26px",
                letterSpacing: "0.15px",
                fontWeight: 700
            }
        }
    }
}))